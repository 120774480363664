<template>
  <div style="padding: 10px">
    <div>上传文件总大小不要超过2MB</div>
    <div class="row">
      <a-upload
        class="file-box"
        v-model:file-list="fileListPhoto"
        name="file"
        :max-count="1"
        @change="handleChangePhoto"
        @remove="hadleRemovePhoto"
         :showUploadList="{showRemoveIcon:false}"
      >
        <a-button class="upload-btn">
          <upload-outlined></upload-outlined>
          上传白底一寸照片
        </a-button>
      </a-upload>

      <div class="descr-box">
        <span class="text-box">附件描述</span
        ><el-input class="el-input input" type="text" v-model="fileDescr1" />
      </div>

      <div class="action-box">
        <button class="button btn-middle" @click="removePhoto">-</button>
        <button class="button btn-middle" style="visibility: hidden">+</button>
      </div>
    </div>
    <div class="row">
      <a-upload
        class="file-box"
        v-model:file-list="fileListNid"
        name="file"
        :max-count="1"
        @change="handleChangeNid"
        @remove="hadleRemoveNid"
         :showUploadList="{showRemoveIcon:false}"
      >
        <a-button class="upload-btn">
          <upload-outlined></upload-outlined>
          上传身份证人像面
        </a-button>
      </a-upload>

      <div class="descr-box">
        <span class="text-box">附件描述</span
        ><el-input class="el-input input" type="text" v-model="fileDescr2" />
      </div>

      <div class="action-box">
        <button class="button btn-middle" @click="removeNid">-</button>
        <button class="button btn-middle" style="visibility: hidden">+</button>
      </div>
    </div>
    <div class="row">
      <a-upload
        class="file-box"
        v-model:file-list="fileListNidBack"
        name="file"
        :max-count="1"
        @change="handleChangeNidBack"
        @remove="hadleRemoveNidBack"
         :showUploadList="{showRemoveIcon:false}"
      >
        <a-button class="upload-btn">
          <upload-outlined></upload-outlined>
          上传身份证国徽面
        </a-button>
      </a-upload>

      <div class="descr-box">
        <span class="text-box">附件描述</span
        ><el-input class="el-input input" type="text" v-model="fileDescr3" />
      </div>

      <div class="action-box">
        <button class="button btn-middle" @click="removeNidBack">-</button>
        <button class="button btn-middle" style="visibility: hidden">+</button>
      </div>
    </div>
    <div class="row">
      <a-upload
        class="file-box"
        v-model:file-list="fileListBank"
        name="file"
        :max-count="1"
        @change="handleChangeBank"
        @remove="hadleRemoveBank"
         :showUploadList="{showRemoveIcon:false}"
      >
        <a-button class="upload-btn">
          <upload-outlined></upload-outlined>
          上传银行卡卡号面
        </a-button>
      </a-upload>

      <div class="descr-box">
        <span class="text-box">附件描述</span
        ><el-input class="el-input input" type="text" v-model="fileDescr4" />
      </div>

      <div class="action-box">
        <!-- <button class="button btn-middle" 
       Bank">+</button -->
        <button class="button btn-middle" @click="removeBank">-</button>
        <button class="button btn-middle" style="visibility: hidden">+</button>
      </div>
    </div>
    <div class="row" v-for="(item, index) in languageList" :key="index">
      <a-upload
        class="file-box"
        v-model:file-list="item.uploadList"
        name="file"
        :max-count="1"
        @change="handleChangeLanguage(index, $event)"
        @remove="hadleRemoveLanguage"
         :showUploadList="{showRemoveIcon:false}"
      >
        <a-button class="upload-btn">
          <upload-outlined></upload-outlined>
          上传语言能力相关证书
        </a-button>
      </a-upload>

      <div class="descr-box">
        <span class="text-box">附件描述</span
        ><el-input class="el-input input" type="text" v-model="item.descr" />
      </div>

      <div class="action-box">
        <button class="button btn-middle" @click="addLanguage(index)">+</button
        ><button class="button btn-middle" @click="removeLanguage(index)">
          -
        </button>
      </div>
    </div>
    <div class="row" v-for="(item, index) in proQuaNameList" :key="index">
      <a-upload
        class="file-box"
        v-model:file-list="item.uploadList"
        name="file"
        @change="handleChangeJobTit(index, $event)"
        @remove="hadleRemoveJobTit"
        :max-count="1"
         :showUploadList="{showRemoveIcon:false}"
      >
        <a-button class="upload-btn">
          <upload-outlined></upload-outlined>
          上传职称信息相关证书
        </a-button>
      </a-upload>

      <div class="descr-box">
        <span class="text-box">附件描述</span
        ><el-input class="el-input input" type="text" v-model="item.descr" />
      </div>

      <div class="action-box">
        <button class="button btn-middle" @click="addJobTit(index)">+</button
        ><button class="button btn-middle" @click="removeJobTit(index)">
          -
        </button>
      </div>
    </div>
    <div class="row" v-for="(item, index) in proQualification" :key="index">
      <a-upload
        class="file-box"
        v-model:file-list="item.uploadList"
        name="file"
        @change="handleChangeQua(index, $event)"
        @remove="hadleRemoveQua"
        :max-count="1"
         :showUploadList="{showRemoveIcon:false}"
      >
        <a-button class="upload-btn">
          <upload-outlined></upload-outlined>
          {{ item.title }}相关证书
        </a-button>
      </a-upload>
      <div class="descr-box">
        <span class="text-box">附件描述</span
        ><el-input class="el-input input" type="text" v-model="item.descr" />
      </div>
      <div class="action-box">
        <button class="button btn-middle" @click="addQua(index)">+</button
        ><button class="button btn-middle" @click="removeQua(index)">-</button>
      </div>
    </div>

    <!-- ann -->

    <button
      class="btn-middle button sub"
      @click="select"
      style="width: 100%; margin-top: 40px"
    >
      保存附件
    </button>
  </div>
</template>
<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { onBeforeRouteLeave } from "vue-router";
import { common } from "../mixin/common";
import { request } from "../mixin/axios";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
const store = useStore();

const { imgToBase64 } = common.methods;
// 数据请求接口
const urlLogin = "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
const { requestPost } = request.methods;
//======渲染fileList的变量定义=========
const fileListPhoto = ref([]);
const fileDescr1 = ref("");
const fileListNid = ref([]);
const fileDescr2 = ref("");
const fileListNidBack = ref([]);
const fileDescr3 = ref("");
const fileListBank = ref([]);
const fileDescr4 = ref("");
const fileImgList1 = ref([]);
const resLogin = computed(() => {
  return store.state.userInfo.resLogin;
});

const proQualification = ref([
  {
    title: "上传职业资格",
    uploadList: [],
    descr: "",
    seqNum: "1",
  },
]);

const proQuaNameList = ref([
  {
    title: "上传职称",
    uploadList: [],
    descr: "",
    seqNum: "1",
  },
]);

const languageList = ref([
  {
    title: "上传语言能力",
    uploadList: [],
    descr: "",
    seqNum: "1",
  },
]);

const attachInfo = ref([]);

//......

//==================================================

// 文件类型转换

const select = () => {
  // console.log(attachInfo.value, "------------");
  // return;
  if (fileListPhoto.value[0]) {
    fileListPhoto.value[0].descr = fileDescr1.value;
  }
  if (fileListNid.value[0]) {
    fileListNid.value[0].descr = fileDescr2.value;
  }
  if (fileListNidBack.value[0]) {
    fileListNidBack.value[0].descr = fileDescr3.value;
  }
  if (fileListBank.value[0]) {
    fileListBank.value[0].descr = fileDescr4.value;
  }

  let a = proQualification.value.map((i) => {
    if (i.uploadList.length !== 0) {
      i.uploadList[0].descr = i.descr;
      return i.uploadList[0];
    }
  });
  let b = proQuaNameList.value.map((i) => {
    if (i.uploadList.length !== 0) {
      i.uploadList[0].descr = i.descr;
      return i.uploadList[0];
    }
  });

  let c = languageList.value.map((i) => {
    if (i.uploadList.length !== 0) {
      i.uploadList[0].descr = i.descr;
      return i.uploadList[0];
    }
  });

  attachInfo.value = [
    ...fileListPhoto.value,
    ...fileListNid.value,
    ...fileListNidBack.value,
    ...fileListBank.value,
    ...a,
    ...b,
    ...c,
  ];

  attachInfo.value = attachInfo.value.filter((item) => item !== undefined);

  console.log(attachInfo.value, "00000000000");
  // return

  store.dispatch("setLoading", true);
  const requestBody = {
    language: "ZHS",
    appversion: "1.0",
    devicetype: "PC",
    deviceid: "35d1298095ac0ef1",
    devicever: "PC",
    infmod: "RC",
    infid: "SAVEATTACH",
    biz: {
      // id: "125",
      id: resLogin.value.form["base-info"].DC_CANDDT_ID,
      attachInfo: attachInfo.value,
    },
  };
  requestPost(urlLogin, requestBody, "0000")
    .then((back) => {
      if (back.response.status == 200) {
        store.dispatch("setLoading", false);
        store.dispatch("setButtonValue", true);
      }
    })
    .catch((error) => {
      console.log("提交附件失败原因：", error);

      store.dispatch("setLoading", false);
      // ElMessageBox.alert("提交附件失败", "提交失败", {
      //   confirmButtonText: "确认",
      // });
    });
  // store.dispatch("storeImgFileValue", attachInfo.value);
};

//获取附件信息
const getImgData = () => {
  store.dispatch("setLoading", true);
  const requestBody = {
    language: "ZHS",
    appversion: "1.0",
    devicetype: "PC",
    deviceid: "35d1298095ac0ef1",
    devicever: "V1",
    infmod: "RC",
    infid: "GETATTACH",
    biz: {
      // id: "125",
      id: resLogin.value.form["base-info"].DC_CANDDT_ID,
    },
  };
  requestPost(urlLogin, requestBody, "0000")
    .then((back) => {
      fileImgList1.value = back.response.data.returnData.attachInfo;

      fileListNid.value = fileImgList1.value.filter((item) => {
        return item.attachType == "23";
      });
      if (fileListNid.value.length > 0) {
        fileDescr2.value =
          fileListNid.value[0] && fileListNid.value[0].descr
            ? fileListNid.value[0].descr
            : "";
        fileListNid.value[0] = fileListNid.value[0]
          ? fileFormatter(fileListNid.value[0])
          : "";
      }

      fileListNidBack.value = fileImgList1.value.filter((item) => {
        return item.attachType == "10";
      });
      if (fileListNidBack.value.length > 0) {
        fileDescr3.value =
          fileListNidBack.value[0] && fileListNidBack.value[0].descr
            ? fileListNidBack.value[0].descr
            : "";
        fileListNidBack.value[0] =
          fileListNidBack.value[0] && fileListNidBack.value[0]
            ? fileFormatter(fileListNidBack.value[0])
            : "";
      }

      fileListPhoto.value = fileImgList1.value.filter((item) => {
        return item.attachType == "11";
      });

      if (fileListPhoto.value.length > 0) {
        fileDescr1.value =
          fileListPhoto.value[0] && fileListPhoto.value[0].descr
            ? fileListPhoto.value[0].descr
            : "";
        fileListPhoto.value[0] =
          fileListPhoto.value[0] && fileListPhoto.value[0]
            ? fileFormatter(fileListPhoto.value[0])
            : "";
      }

      fileListBank.value = fileImgList1.value.filter((item) => {
        return item.attachType == "12";
      });
      if (fileListBank.value.length > 0) {
        fileDescr4.value =
          fileListBank.value[0].descr && fileListBank.value[0].descr
            ? fileListBank.value[0].descr
            : "";
        fileListBank.value[0] =
          fileListBank.value[0] && fileListBank.value[0]
            ? fileFormatter(fileListBank.value[0])
            : "";
      }

      console.log(
        fileListBank.value,
        "11111111111114333333333333333333222s",
        fileListBank.value[0]
      );
      // 多个的时候
      let c = fileImgList1.value.filter((item) => {
        return item.attachType == "17";
      });

      if (c.length > 0) {
        proQualification.value = [];
        c.map((item) => {
          let obj = {
            title: "上传职业资格",
            uploadList: [fileFormatter(item)],
            descr: item.descr,
          };
          proQualification.value.push(obj);
        });
      }
      //
      let d = fileImgList1.value.filter((item) => {
        return item.attachType == "18";
      });

      if (d.length > 0) {
        proQuaNameList.value = [];
        d.map((item) => {
          let obj = {
            title: "上传职称",
            uploadList: [fileFormatter(item)],
            descr: item.descr,
          };
          proQuaNameList.value.push(obj);
        });
      }
      //
      let e = fileImgList1.value.filter((item) => {
        return item.attachType == "19";
      });

      if (e.length > 0) {
        languageList.value = [];
        e.map((item) => {
          let obj = {
            title: "上传职业资格",
            uploadList: [fileFormatter(item)],
            descr: item.descr,
          };
          languageList.value.push(obj);
        });
      }

      if (back.response.status == 200) {
        store.dispatch("setLoading", false);
      }
    })
    .catch((error) => {
      console.log("roo", error);

      store.dispatch("setLoading", false);
      // ElMessageBox.alert("获取附件信息失败", "错误", {
      //   confirmButtonText: "确认",
      // });
    });
};

onMounted(() => {
  getImgData();
});
// 对上传成功返回的数据进行格式化处理，格式化a-upload能显示在已上传列表中的格式（这个格式官方文档有给出的）
const fileFormatter = (data) => {
  if (data) {
    let file = {
      name: data.attachName,
      attachType: data.attachType,
      attachSysName: data.attachSysName,
      attachName: data.attachName,
      descr: data.descr,
      fileBase64: data.attachBase64Str,
      seqNum: data.seqNum,
    };
    return file;
  }
};

//=============各个类型文件变化事件===========
const handleChangePhoto = async (info) => {
  store.dispatch("setButtonValue", false);

  let a = await imgToBase64(info.fileList);
  fileListPhoto.value = [
    {
      name: info.fileList[0].name,
      fileBase64: a[0],
      attachType: "11",
      attachSysName: info.fileList[0].uid + info.fileList[0].name,
      attachName: info.fileList[0].name,
      descr: "",
      seqNum:"1"
    },
  ];
};
const removePhoto = () => {
  fileListPhoto.value = [];
  fileDescr1.value = "";
  store.dispatch("setButtonValue", false);

};
//shenfenzheng
const handleChangeNid = async (info) => {
  store.dispatch("setButtonValue", false);

  let a = await imgToBase64(info.fileList);
  fileListNid.value = [
    {
      name: info.fileList[0].name,
      fileBase64: a[0],
      attachType: "10",
      attachSysName: info.fileList[0].uid + info.fileList[0].name,
      attachName: info.fileList[0].name,
      descr: "",
      seqNum:"1"
    },
  ];
};
const removeNid = () => {
  fileListNid.value = [];
  fileDescr2.value = "";
  store.dispatch("setButtonValue", false);

};
//fanmian
const handleChangeNidBack = async (info) => {
  store.dispatch("setButtonValue", false);

  let a = await imgToBase64(info.fileList);
  fileListNidBack.value = [
    {
      name: info.fileList[0].name,
      fileBase64: a[0],
      attachType: "23",
      attachSysName: info.fileList[0].uid + info.fileList[0].name,
      attachName: info.fileList[0].name,
      descr: "",
      seqNum:"1"
    },
  ];
};
const removeNidBack = () => {
  fileListNidBack.value = [];
  fileDescr3.value = "";
  store.dispatch("setButtonValue", false);

};
//yinhangka

const handleChangeBank = async (info) => {
  store.dispatch("setButtonValue", false);

  console.log(info.fileList, "888888");
  let a = await imgToBase64(info.fileList);
  fileListBank.value = [
    {
      name: info.fileList[0].name,
      fileBase64: a[0],
      attachType: "12",
      attachSysName: info.fileList[0].uid + info.fileList[0].name,
      attachName: info.fileList[0].name,
      descr: "11111",
    },
  ];
};
const removeBank = () => {
  fileListBank.value = [];
  fileDescr4.value = "";
  store.dispatch("setButtonValue", false);

};
// 上传职业资格添加按钮
const addQua = (index) => {
  let obj = {
    title: "上传职业资格",
    uploadList: [],
    descr: "",
    seqNum: `${index + 1}`,
  };
  proQualification.value.push(obj);
};
const removeQua = (index) => {
  if (index != 0) {
    proQualification.value.splice(index, 1);
  } else {
    proQualification.value[index] = {
      title: "上传职业资格",
      uploadList: [],
      descr: "",
      seqNum: "1",
    };
    store.dispatch("setButtonValue", false);

  }
};
const handleChangeQua = async (index, info) => {
  store.dispatch("setButtonValue", false);

  console.log("index:", index);

  let a = await imgToBase64(info.fileList);

  proQualification.value.map((item, ind) => {
    if (index == ind) {
      item.uploadList = [
        {
          name: info.fileList[0].name,
          fileBase64: a[0],
          attachType: "17",
          attachSysName: info.fileList[0].uid + info.fileList[0].name,
          attachName: info.fileList[0].name,
          descr: "",
          seqNum: `${index + 1}`,
        },
      ];
    }
  });
};

// 上传职cheng
const addJobTit = (index) => {
  let obj = {
    title: "上传职业资格",
    uploadList: [],
    descr: "",
    seqNum: `${index + 1}`,
  };
  proQuaNameList.value.push(obj);
};
const removeJobTit = (index) => {
  if (index != 0) {
    proQuaNameList.value.splice(index, 1);
  }else {
    proQuaNameList.value[index] = {
      title: "上传职称",
      uploadList: [],
      descr: "",
      seqNum: "1",
    };
    store.dispatch("setButtonValue", false);

  }
};

const handleChangeJobTit = async (index, info) => {
  store.dispatch("setButtonValue", false);

  let a = await imgToBase64(info.fileList);

  proQuaNameList.value.map((item, ind) => {
    if (index == ind) {
      item.uploadList = [
        {
          name: info.fileList[0].name,
          fileBase64: a[0],
          attachType: "18",
          attachSysName: info.fileList[0].uid + info.fileList[0].name,
          attachName: info.fileList[0].name,
          descr: "",
          seqNum: `${index + 1}`,
        },
      ];
    }
  });
};

// 上传语言能力
const addLanguage = (index) => {
  let obj = {
    title: "上传职业资格",
    uploadList: [],
    descr: "",
    seqNum: `${index + 1}`,
  };
  languageList.value.push(obj);
};
const removeLanguage = (index) => {
  if (index != 0) {
    languageList.value.splice(index, 1);
  }else {
    languageList.value[index] = {
      title: "上传语言能力",
      uploadList: [],
      descr: "",
      seqNum: "1",
    };
    store.dispatch("setButtonValue", false);

  }
};
const handleChangeLanguage = async (index, info) => {
  store.dispatch("setButtonValue", false);

  let a = await imgToBase64(info.fileList);

  languageList.value.map((item, ind) => {
    if (index == ind) {
      item.uploadList = [
        {
          name: info.fileList[0].name,
          fileBase64: a[0],
          attachType: "19",
          attachSysName: info.fileList[0].uid + info.fileList[0].name,
          attachName: info.fileList[0].name,
          descr: "",
          seqNum: `${index + 1}`,
        },
      ];
    }
  });
};

onBeforeRouteLeave((to, from, next) => {
  console.log(store.state.isSubmitButtonClicked);
  
  if (!store.state.isSubmitButtonClicked) {
    ElMessageBox.alert("请先点击 -保存附件- 按钮", "警告", {
      confirmButtonText: "返回",
    });
    next(false);
  } else {
    next();
  }
});
</script>
<style scoped>
.row {
  display: flex;
  margin: 10px;
  justify-content: space-between;
  border: 1px solid rgb(177, 177, 177);
  padding: 10px;
  border-radius: 10px;
}
.button {
  font-size: 2rem;
}
.upload-btn {
  width: 13em;
  text-align: center;
}
.descr-box {
  .el-input {
    width: 20em;
  }
  .text-box {
    margin-left: 15px;
    margin-right: 10px;
  }
}
.action-box {
  display: flex;
  gap: 10px;
  margin-left: 20px;
  align-items: center;
  justify-content: end;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .row {
    flex-direction: column;
  }
  .descr-box {
    display: flex;
    margin-top: 5px;
    .el-input {
      width: 13em;
    }
    .text-box {
      margin-left: 3px;
    }
  }
  .action-box {
    margin-top: 5px;
  }
}
.sub {
  font-size: 1rem !important;
}
</style>
