<template>
  <div class="all-box">
    <lay-loading v-if="loading" :type="2" :loading="loading" tip="loading..."
      style="position: fixed; height: 100vh; opacity: 0.5; z-index: 99999"></lay-loading>
    <vue-particles id="tsparticles" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="options"
      :style="{
        position: 'relative',
        zIndex: '0',
      }" />
    <div class="login-box">
      <!-- <form class="login-form">
        <h2>登&nbsp;&nbsp;录</h2>
        <div class="info-box">
          <div class="info">
            <span>账号</span
            ><input
              v-model="nId"
              type="text"
              name="nId"
              class="input"
              placeholder="&nbsp;&nbsp;请输入身份证号"
            />
            <div class="warning">
              <p v-show="isNidValid">身份证号格式不正确</p>
            </div>
          </div>
          <div class="info">
            <span>密码</span
            ><input
              v-model="nidShort"
              type="text"
              name="nidShort"
              class="input"
              placeholder="&nbsp;&nbsp;请输入身份证号后六位"
            />
            <div class="warning">
              <p v-show="isNidShortValid">身份证后六位格式不正确</p>
            </div>
          </div>
        </div>
        <div>
          <el-button style="margin-top: 20px" >登录</el-button>
        </div>
      </form> -->
    </div>
  </div>
</template>
<script>
import { loadSlim } from "tsparticles-slim";
import { onMounted, ref } from "vue";
import { ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { validationMixin } from "../mixin/validationMixin";
import { request } from "../mixin/axios";
import "@layui/layui-vue/lib/index.css";
import { useRoute } from "vue-router";

export default {
  name: "index",
  props: {},
  components: {},
  mixins: [validationMixin, request],
  setup() {
    const loading = ref(false);
    const resContent = ref("");
    let resLogin = {};
    const router = useRouter();
    const nidShort = ref("");
    const nId = ref("");
    const isNidShortValid = ref(false);
    const isNidValid = ref(false);
    //背景图申请接口
    const urlBg =
      "https://qywx.sinovacbio.cn/qywxai/api/public/getNewBackgroudImg";
    // 数据请求接口
    const urlLogin =
      "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";

    // const { chekNidFull, chekNidShort } = validationMixin.methods;
    const { requestPost } = request.methods;
    const source = ref("");
    const route = useRoute();

    onMounted(() => {
      source.value = route.query.source; //获取链接传参
      source.value = source.value.replace(/ /g, "+");//加密的信息中有加号，而连接传参会去掉加号，这里给加回去
      const { requestGet } = request.methods;
      loading.value = true;
      requestGet(urlBg, 1000)
        .then((back) => {
          resContent.value = back.response.data.content;
          if (back.response.status == 200) {
            loading.value = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //协议弹出层
      ElMessageBox.alert(
        `<p>
      &nbsp;&nbsp;&nbsp;&nbsp;公司将通过本次登记收集员工信息，并采取必要的技术和组织措施，
      确保数据的安全、保密和完整性。收集的员工数据将仅由授权人员访问和处理，并仅在实现处理目的所必需的范围内使用。</p>
      <p>请您真实完整的填写相关内容，您的个人信息公司将严格保密。</p>
      <p>感谢您的理解与支持。</p>
      <p>1）表单内已存在且允许修改的信息，请您核对，若有调整，请直接修改；</p>
      <p>2）表单中未填写的信息，请您补充完整；</p>
      <p>3）本表中基本信息、亲属信息、教育信息、地址信息、银行账户、附件信息为必填信息，需全部填写完成后才可点击提交。</p>`,
        "个人数据处理告知",
        {
          dangerouslyUseHTMLString: true,
          "show-close": false,
          showCancelButton: true,
          confirmButtonText: "同意",
          cancelButtonText: "拒绝",
          center: true,
        }
      )
        .then(() => {
          const requestBody = {
            language: "ZHS",
            appversion: "1.0",
            devicetype: "PC",
            deviceid: "35d1298095ac0ef1",
            devicever: "PC",
            infmod: "RC",
            infid: "DATA",
            biz: {
              // id: "k1v3VOHWYQc="
              id: source.value,
            },
          };
          requestPost(urlLogin, requestBody, "0000")
            .then((back) => {
              if (back.response.data.returnData.form.status == "12" || back.response.data.returnData.form.status == "11" || back.response.data.returnData.form.status == "14") {
                resLogin = { ...back.response.data.returnData };
                loading.value = back.loading;
                router.push({
                  path: "/all",
                  query: { resLogin: JSON.stringify(resLogin) },
                });
              } else {
                ElMessageBox.alert("没有权限，请联系HR", "警告", {
                  confirmButtonText: "确认",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
          // router.push({
          //       path: "/all",
          //       query: { resLogin: JSON.stringify(resLogin) },
          //     });
        })
        .catch(() => {
          ElMessageBox.alert("已拒绝，无权访问", "警告", {
            confirmButtonText: "确认",
          });
        });
    });
    /*  //登录按钮
    const login = () => {
      loading.value = true;
      isNidValid.value = !chekNidFull(nId.value);
      isNidShortValid.value = !chekNidShort(nidShort.value);
      if (!isNidValid.value && !isNidShortValid.value) {
        //登录
        const requestBody = {
          language: "ZHS",
          appversion: "1.0",
          devicetype: "PC",
          deviceid: "35d1298095ac0ef1",
          devicever: "PC",
          infmod: "RC",
          infid: "DATA",
          biz: {
            id: "100002",
            // id: "200456207",
            // nid: nId,
            // password: nidShort,

            // id:"200456207",
            // nid:"230229199705155449",
            // password:"155449"
          },
        };
        requestPost(urlLogin, requestBody, "0000")
          .then((back) => {
            resLogin = { ...back.response.data.returnData };
            loading.value = back.loading;
            router.push({
              path: "/all",
              query: { resLogin: JSON.stringify(resLogin) },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("账号密码填写有误");
        loading.value = false;
      }
    };
    */
    //配置粒子特效
    const particlesInit = async (engine) => {
      await loadSlim(engine);
    };
    const options = {
      // background: {
      //   color: {
      //     value: "#2d3a4b",
      //   },
      // },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 2,
            opacity: 0.8,
            size: 40,
          },
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        collisions: {
          enable: true,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            area: 800,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    };

    return {
      options,
      particlesInit,
      resContent,
      isNidShortValid,
      isNidValid,
      nId,
      nidShort,
      loading,
    };
  },
};
</script>
<style>
.all-box {
  background-image: url('@/assets/登录页背景.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.el-button {
  background-color: var(--button-color) !important;
  color: var(--button-text-color) !important;
  border: none !important;
}

.el-button:hover {
  background-color: var(--button-hover-color) !important;
  color: var(--button-text-color) !important;
  border: none !important;
}

.el-button:focus-visible {
  outline: none !important;
}

.login-box {
  background-color: rgba(255, 0, 0, 0);
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 999;
  overflow: hidden;
}

.login-form {
  color: var(--color-text-black);
  background-color: rgba(255, 255, 255, 0.944);
  width: 30%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  /* gap: 40px; */
  align-items: center;
  min-height: 300px;
  position: fixed;
  top: 20%;
  left: 55%;
  border-radius: 5px;
  padding: 10px;
}

.info-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* gap: 0px; */
  margin: 40px 0px 10px 0px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  span {
    width: 20%;
    text-align: end;
  }

  .input {
    width: 60%;
    border: 2px solid rgb(237, 237, 237);
    height: 30px;
    border-radius: 5px;
    font-size: 12px;
    margin-left: 5px;

    /* min-width: 20px; */
  }

  .warning {
    width: 100%;
    height: 30px;
    /* background-color: red; */
    text-align: center;
    color: var(--warning-color-red);
    font-size: var(--warning-text-size);
  }
}

@media only screen and (max-width: 768px) {

  /* 手机端适配 */
  .login-form {
    top: 20%;
    left: 19%;
    min-width: 65%;
    height: 30vh;
  }

  .all-box {
    background-image: url('@/assets/bg-phone.png');
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  /* 平板电脑的样式 */
  .login-form {
    top: 30%;
    left: 19%;
    min-width: 65%;
    height: 20vh;
  }
}
</style>
